import React from 'react'
import thumbnailStateOfKafkaReplicationImage from '../../../resources/images/featured_resources/thumbnails/state-of-kafka-replication.webp'
import thumbnailLenses6PanoptesImage from '../../../resources/images/featured_resources/thumbnails/lenses-6-0-panoptes.webp'
import thumbnailPressReleaseImage from '../../../resources/images/featured_resources/thumbnails/press-release.webp'
import authorAndrewStevensonImage from '../../../resources/images/featured_resources/authors/andrew-stevenson.webp'
import authorLensesImage from '../../../resources/images/featured_resources/authors/lenses.webp'
import { DemoIcon, DownloadBigIcon } from '../../../hanzo/lib/components/basics/icons'
import { Type } from '../../../hanzo/pages/home/constants'
import philFalkenholmImage from '../../../resources/images/case_studies/arity/phil-falkenholm.webp'

export const achievements = [
    {
        title: '40% operational cost reduction',
        description: 'In operational overhead and personnel',
    },
    {
        title: '3 Kafka clusters',
        description: 'In less than 5 months',
    },
    {
        title: '25% reduction',
        description: 'In service tickets',
    },
]

export const sectionIds = {
    arity: 'Arity',
    challenge: 'Challenge',
    solution: 'Solution',
    transformation: 'Transformation',
    benefits: 'Benefits',
}

export const sectionKeys = {
    arity: 'arity',
    challenge: 'challenge',
    solution: 'solution',
    transformation: 'transformation',
    benefits: 'benefits',
}

export const tableOfContents = [
    { key: sectionKeys.arity, title: 'Arity', id: `#${sectionIds.arity}` },
    { key: sectionKeys.challenge, title: 'Challenge', id: `#${sectionIds.challenge}` },
    { key: sectionKeys.solution, title: 'Solution', id: `#${sectionIds.solution}` },
    {
        key: sectionKeys.transformation,
        title: 'Transformation',
        id: `#${sectionIds.transformation}`,
    },
    { key: sectionKeys.benefits, title: 'Benefits', id: `#${sectionIds.benefits}` },
]

export const companyPresentation = {
    person: {
        name: 'Phil Falkenholm',
        position: 'Director of Infrastructure and Security, Arity',
        imageSrc: philFalkenholmImage,
        imageAlt: 'Phil Falkenholm - avatar image',
    },
    lists: [
        {
            title: 'Stack',
            items: ['Self-managed Apache Kafka', 'Amazon MSK'],
        },
        {
            title: 'Transformation',
            items: ['Kafka migration'],
        },
        {
            title: 'Lenses DevX',
            items: [
                'SQL for data exploration',
                'SQL for stream processing',
                'Schema managememt & evolution',
            ],
        },
    ],
}

export const featuredResources = [
    {
        imageSrc: thumbnailStateOfKafkaReplicationImage,
        imageAlt: 'The state of Kafka replication - thumbnail image',
        tag: 'Blog',
        title: 'The state of Kafka replication',
        link: '/blog/2025/02/state-of-kafka-replication/',
        author: {
            imageSrc: authorAndrewStevensonImage,
            imageAlt: 'Andrew Stevenson - avatar image',
            name: 'Andrew Stevenson',
        },
    },
    {
        imageSrc: thumbnailLenses6PanoptesImage,
        imageAlt: 'Introducing Lenses 6.0 Panoptes - thumbnail image',
        tag: 'Blog',
        title: 'Introducing Lenses 6.0 Panoptes',
        link: '/blog/2024/11/lenses-6-panoptes-announcement/',
        author: {
            imageSrc: authorAndrewStevensonImage,
            imageAlt: 'Andrew Stevenson - avatar image',
            name: 'Andrew Stevenson',
        },
    },
    {
        imageSrc: thumbnailPressReleaseImage,
        imageAlt: 'Lenses.io introduces new streaming data replicator - thumbnail image',
        tag: 'Press release',
        title: 'Lenses.io introduces new streaming data replicator',
        link: '/blog/2025/02/introducing-streaming-data-replicator/',
        author: {
            imageSrc: authorLensesImage,
            imageAlt: 'Lenses.io - avatar image',
            name: 'Lenses.io',
        },
    },
]

export const preFooterTitle = 'Try Lenses for yourself'

export const cardsDataPrefooter = [
    {
        title: (
            <>
                20 minute
                <br />
                demo
            </>
        ),
        type: Type.Primary,
        description: [
            'Get a guided tour of Lenses',
            'Receive a trial key to install on your clusters',
            'Perfect for production and large-scale streaming deployments.',
        ],
        buttonText: 'Get a demo',
        href: '/request-demo',
        icon: <DemoIcon key='demoIcon' />,
    },
    {
        title: 'Free Lenses',
        leadingText: 'Run the docker command to get Lenses Community Edition:',
        type: Type.Secondary,
        description: [
            'Apache Kafka Docker + Lenses 6.0',
            'Pre-loaded synthetic data',
            'Up to 2 users + environments',
        ],
        buttonText: 'Download',
        href: '/community-edition/',
        icon: <DownloadBigIcon key='downloadBigIcon' />,
    },
]
