import React from 'react'
import { graphql, PageProps, useStaticQuery } from 'gatsby'
import Layout from '../../../components/Layout'
import HeaderSEO from '../../../components/partials/HeaderSEO'
import { Section } from '../../../components/section'
import { SectionTitle } from '../../../components/section-title'
import * as stylesModule from './_styles.module.scss'
import { CardGroup } from '../../../components/card-group'
import { Card } from '../../../components/card'
import { AchievementGroup } from '../../../components/achievement-group'
import { Achievement } from '../../../components/achievement'
import { AchievementDescription } from '../../../components/achievement-description'
import { AchievementTitle } from '../../../components/achievement-title'
import { CaseStudyHero } from '../../../components/case-study-hero'
import { CaseStudyHeroTitle } from '../../../components/case-study-hero-title'
import { CaseStudyHeroSubtitle } from '../../../components/case-study-hero-subtitle'
import { Page } from '../../../components/page'
import { CaseStudyHeroImage } from '../../../components/case-study-hero-image'
import arityHeroImage from '../../../resources/images/case_studies/arity/hero.webp'
import { CardImage } from '../../../components/card-image'
import { CardTag } from '../../../components/card-tag'
import { CardTitle } from '../../../components/card-title'
import { Link } from 'gatsby'
import {
    achievements,
    featuredResources,
    companyPresentation,
    cardsDataPrefooter,
    preFooterTitle,
    tableOfContents,
    sectionIds,
    sectionKeys,
} from './_constants'
import { CardAuthor } from '../../../components/card-author'
import { ContentBlock } from '../../../components/content-block'
import { ContentBlockTitle } from '../../../components/content-block-title'
import { ContentBlockBody } from '../../../components/content-block-body'
import { Paragraph } from '../../../components/paragraph'
import { Blockquote } from '../../../components/blockquote'
import { Hyperlink } from '../../../components/hyperlink'
import { CaseStudyContent } from '../../../components/case-study-content'
import { CaseStudyContentGroup } from '../../../components/case-study-content-group'
import { CaseStudyContentSidebar } from '../../../components/case-study-content-sidebar'
import { TableOfContents } from '../../../components/table-of-contents'
import { TableOfContentsTitle } from '../../../components/table-of-contents-title'
import { TableOfContentsList } from '../../../components/table-of-contents-list'
import { TableOfContentsListItem } from '../../../components/table-of-contents-list-item'
import { CompanyPresentation } from '../../../components/company-presentation'
import { CompanyPresentationList } from '../../../components/company-presentation-list'
import { CompanyPresentationListItem } from '../../../components/company-presentation-list-item'
import { CompanyPresentationPerson } from '../../../components/company-presentation-person'
import PreFooter from '../../../hanzo/lib/components/modules/prefooter'
import { TableOfContentsMenuBanner } from '../../../components/table-of-contents-menu-banner'
import { useSectionObserver } from '../../../utils/section-observer.hook'

const arityApacheKafkaCostsAmazonMsk = ({ location }: PageProps) => {
    const styles: any = stylesModule
    const currentPath = location.pathname

    const { activeSection, sectionRefs } = useSectionObserver(sectionKeys)

    const data = useStaticQuery(graphql`
        query AritySEO {
            allContentfulPages(
                filter: { pageReference: { pageIdentifier: { eq: "ArityCaseStudy" } } }
            ) {
                edges {
                    node {
                        title {
                            title
                        }
                        keywords {
                            keywords
                        }
                        description {
                            description
                        }
                        oGimage {
                            fluid(quality: 100) {
                                src
                            }
                        }
                    }
                }
            }
        }
    `)

    const pageSEO = data.allContentfulPages.edges[0].node

    return (
        <Layout newsletter={false}>
            <HeaderSEO pageSEO={pageSEO} />
            <Page>
                <CaseStudyHero className={styles.heroSection}>
                    <CaseStudyHeroTitle>
                        <strong>Arity:</strong> Accelerating Kafka adoption and saving costs by 40%
                    </CaseStudyHeroTitle>
                    <CaseStudyHeroSubtitle>
                        With AWS Managed Kafka (MSK) and Lenses.
                    </CaseStudyHeroSubtitle>
                    <CaseStudyHeroImage src={arityHeroImage} alt={'Arity hero image'} />
                </CaseStudyHero>

                <Section className={styles.inFocusSection}>
                    <SectionTitle>In focus</SectionTitle>
                    <AchievementGroup>
                        {achievements.map((achievement, index) => (
                            <Achievement key={index}>
                                <AchievementTitle>{achievement.title}</AchievementTitle>
                                <AchievementDescription>
                                    {achievement.description}
                                </AchievementDescription>
                            </Achievement>
                        ))}
                    </AchievementGroup>

                    <CaseStudyContent>
                        <CaseStudyContentSidebar>
                            <TableOfContentsMenuBanner title={'Table of contents'}>
                                <TableOfContentsList>
                                    {tableOfContents.map((item, index) => (
                                        <TableOfContentsListItem
                                            key={index}
                                            active={item.key === activeSection}>
                                            <Link to={`${currentPath}${item.id}`}>
                                                {item.title}
                                            </Link>
                                        </TableOfContentsListItem>
                                    ))}
                                </TableOfContentsList>
                            </TableOfContentsMenuBanner>
                            <TableOfContents>
                                <TableOfContentsTitle>Table of contents</TableOfContentsTitle>
                                <TableOfContentsList>
                                    {tableOfContents.map((item, index) => (
                                        <TableOfContentsListItem
                                            key={index}
                                            active={item.key === activeSection}>
                                            <Link to={`${currentPath}${item.id}`}>
                                                {item.title}
                                            </Link>
                                        </TableOfContentsListItem>
                                    ))}
                                </TableOfContentsList>
                            </TableOfContents>
                            <CompanyPresentation>
                                <CompanyPresentationPerson
                                    name={companyPresentation.person.name}
                                    position={companyPresentation.person.position}
                                    imageSrc={companyPresentation.person.imageSrc}
                                    imageAlt={companyPresentation.person.imageAlt}
                                />
                                {companyPresentation.lists.map((list, index) => (
                                    <CompanyPresentationList title={list.title} key={index}>
                                        {list.items.map((item, index) => (
                                            <CompanyPresentationListItem key={index}>
                                                {item}
                                            </CompanyPresentationListItem>
                                        ))}
                                    </CompanyPresentationList>
                                ))}
                            </CompanyPresentation>
                        </CaseStudyContentSidebar>
                        <CaseStudyContentGroup id={'case-study-content-group'}>
                            <ContentBlock ref={sectionRefs[sectionKeys.arity]}>
                                <ContentBlockTitle id={sectionIds.arity}>Arity</ContentBlockTitle>
                                <ContentBlockBody>
                                    <Paragraph>
                                        Arity is a mobility data and analytics company that uses
                                        telematics data to better understand and predict driving
                                        behavior. Arity collects and analyzes driving data from over
                                        30 million unique users to help insurance companies,
                                        automobile manufacturers, retail brands, municipalities, and
                                        others manage risk and operate more safely.
                                    </Paragraph>
                                </ContentBlockBody>
                            </ContentBlock>

                            <ContentBlock ref={sectionRefs[sectionKeys.challenge]}>
                                <ContentBlockTitle id={sectionIds.challenge}>
                                    Challenge
                                </ContentBlockTitle>
                                <ContentBlockBody>
                                    <Paragraph>
                                        Supporting 150+ developers and self-managing Kafka was
                                        impacting productivity. The Arity DevOps team was spending
                                        too much time managing and scaling their self-run Apache
                                        Kafka clusters, and servicing support tickets from
                                        engineering teams developing on Kafka. This was impacting
                                        Arity’s ability to grow their adoption of real-time data
                                        streams. The goal was clear: reduce DevOps being the
                                        bottleneck, give more self-service control to developers,
                                        and remove the need to manage Kafka infrastructure.
                                    </Paragraph>
                                </ContentBlockBody>
                            </ContentBlock>

                            <ContentBlock ref={sectionRefs[sectionKeys.solution]}>
                                <ContentBlockTitle id={sectionIds.solution}>
                                    Solution
                                </ContentBlockTitle>
                                <ContentBlockBody>
                                    <Paragraph>
                                        Embracing a Kafka managed service with a leading Developer
                                        Experience. Enter Lenses.io and Amazon MSK. This powerful
                                        duo offered Arity a fully managed solution with Lenses for
                                        the Developer Experience building event-driven applications
                                        on Kafka, transforming their streaming platform and data
                                        operations. Arity&apos;s developers gained self-service
                                        visibility into the performance of applications & ability to
                                        explore data without needing to raise support tickets to
                                        DevOps.
                                    </Paragraph>
                                    <Paragraph>
                                        By eliminating the operational burden required to provision
                                        servers, orchestrate patches and upgrades, Amazon MSK
                                        further freed up technical teams and resources to focus on
                                        application development and business results.
                                    </Paragraph>
                                    <Blockquote>
                                        “Whether it’s migration, security, or a new functionality
                                        that can improve our business, Lenses.io and AWS are always
                                        there for us. They&apos;re two very good partners.”
                                    </Blockquote>
                                </ContentBlockBody>
                            </ContentBlock>

                            <ContentBlock ref={sectionRefs[sectionKeys.transformation]}>
                                <ContentBlockTitle id={sectionIds.transformation}>
                                    Transformation
                                </ContentBlockTitle>
                                <ContentBlockBody>
                                    <Paragraph>
                                        A smooth, fast Kafka migration. Lenses offered Arity
                                        visibility into their applications connected to Kafka
                                        offered Lenses as they were being migrated. As well as the
                                        operational savings of MSK & managing Kafka, data
                                        observability with Lenses was a game-changer. Within five
                                        months, Arity successfully migrated three Kafka clusters,
                                        housing hundreds of applications and topics, to Amazon MSK.
                                        This move wasn&apos;t just about changing platforms; it was
                                        about upgrading their entire way of working with streaming
                                        data.
                                    </Paragraph>
                                </ContentBlockBody>
                            </ContentBlock>

                            <ContentBlock ref={sectionRefs[sectionKeys.benefits]}>
                                <ContentBlockTitle id={sectionIds.benefits}>
                                    Benefits
                                </ContentBlockTitle>
                                <ContentBlockBody>
                                    <Paragraph>
                                        More than just efficiency. A 200% improvement in operational
                                        efficiency and a 40% cut in costs were just the tip of the
                                        iceberg. The real win was in how Arity&apos;s teams could
                                        now redirect their focus towards enhancing applications and
                                        driving more business value, thanks to the robustness and
                                        ease of managing Kafka through Amazon MSK and Lenses.io.
                                        This case study was originally published on&nbsp;
                                        <Hyperlink href='https://aws.amazon.com/partners/success/arity-lenses-io/'>
                                            aws.amazon.com
                                        </Hyperlink>
                                    </Paragraph>
                                </ContentBlockBody>
                            </ContentBlock>
                        </CaseStudyContentGroup>
                    </CaseStudyContent>
                </Section>

                <Section className={styles.featuredResourcesSection}>
                    <SectionTitle>Featured Resources</SectionTitle>
                    <CardGroup>
                        {featuredResources.map((featuredResource, index) => (
                            <Card key={index}>
                                <CardImage
                                    src={featuredResource.imageSrc}
                                    alt={featuredResource.imageAlt}
                                />
                                <CardTag>{featuredResource.tag}</CardTag>
                                <CardTitle>
                                    <Link to={featuredResource.link}>{featuredResource.title}</Link>
                                </CardTitle>
                                <CardAuthor
                                    imageSrc={featuredResource.author.imageSrc}
                                    imageAlt={featuredResource.author.imageAlt}
                                    name={featuredResource.author.name}
                                />
                            </Card>
                        ))}
                    </CardGroup>
                </Section>

                <section className={styles.ctaSection}>
                    <PreFooter title={preFooterTitle} cardsData={cardsDataPrefooter} />
                </section>
            </Page>
        </Layout>
    )
}

export default arityApacheKafkaCostsAmazonMsk
